import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import config from "@/configs";
import Cookies from "js-cookie";
import { CommonObj, HttpResponse } from "@/types";
import { Message } from "element-ui";

const customAxios = axios.create(
  Object.assign({}, config.base, config[process.env.NODE_ENV!])
);

// 请求队列，避免短时间内重复请求
const reqList: Array<{
  url: string;
  configs?: string;
  resp: Promise<any>;
}> = [];

const deepClone = <T extends CommonObj>(obj: T): T => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  let result: any = typeof obj.splice === "function" ? [] : {};
  let key;
  if (obj && typeof obj === "object") {
    for (key in obj) {
      if (obj[key] && typeof obj[key] === "object") {
        result[key] = deepClone(obj[key]);
      } else {
        result[key] = obj[key];
      }
    }
    return result;
  }
  return obj;
};

const userInterceptors = () => {
  customAxios.interceptors.request.use(
    (config) => {
      if (!config.params) {
        config.params = {};
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
  customAxios.interceptors.response.use(
    (response) => {
      // 用户未登录
      if (response.data.err === 4001) {
        const href = location.href;
        console.warn(`4001: ${response.config.url}`);
        // 不是登录页面或导出页面
        if (
          href.indexOf("/login/content") === -1 &&
          (window as any).exportMode !== true
        ) {
          Cookies.remove("userInfo");
          localStorage.clear();
          window.location.reload();
        }
        return response;
      }
      // 无数据
      if (response.data.err === 1) {
        response.data.err = 0;
        response.data.isEmpty = true;
        return response;
      }
      // 有错误信息 就显示错误信息
      if (
        (response.data.err === 500 || response.data.err === 502) &&
        response.data.msg
      ) {
        if ((window as any).ERROR_MSG) {
          Message({
            type: "error",
            message: response.data.msg,
          });
        }
        return response;
      }

      return response;
    },
    (error) => {
      console.log(JSON.parse(JSON.stringify(error)));
      if (error.message == "Network Error" && !(window as any).showError) {
        (window as any).showError = true;
        Message({
          type: "error",
          message: "网络错误，请检查网络并刷新页面重试",
          duration: 10000,
        });
        setTimeout(() => {
          (window as any).showError = false;
        }, 5000);
      }
      return Promise.reject(error);
    }
  );
  //
  customAxios.interceptors.response.use(
    undefined,
    function axiosRetryInterceptor(err) {}
  );
};

const http = async (
  url: string,
  configs?: AxiosRequestConfig,
  // 直接返回response  文件下载的时候需要拿到header
  returnResponse: boolean = false
): Promise<HttpResponse> => {
  try {
    let match = reqList.find((item) => {
      if (configs) {
        return item.url === url && item.configs === JSON.stringify(configs);
      } else {
        return item.url === url;
      }
    });
    if (match) {
      let res = await match.resp;
      // 短时间内重复请求，直接返回第一次的结果并深复制避免原数据可能被修改
      res = deepClone(res);
      if (returnResponse) {
        return res;
      }
      return res.data;
    } else {
      let item = {
        url,
        configs: configs ? JSON.stringify(configs) : "",
        resp: customAxios(url, configs) as Promise<any>,
      };
      reqList.push(item);
      let res = await item.resp;
      reqList.splice(reqList.indexOf(item), 1);
      if (returnResponse) {
        return res;
      }
      return res.data;
    }
  } catch (exception) {
    return {
      exception,
      err: -99,
      msg: "async error",
      err_code: -99,
    } as any;
  }
};

userInterceptors();

export { customAxios, http };
