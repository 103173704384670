<template>
  <div
    class="base-crud"
    v-loading="loading"
    element-loading-text="数据加载中..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="table-header" v-if="table.showHeader != false">
      <div class="table-header-left">
        <slot name="create"></slot>
        <BaseColumnFilter
          :filters="filters"
          v-if="filters && filters.length > 0"
          @handleSearch="handleSearch"
        />
      </div>
      <div class="table-header-right">
        <BaseCrudBatchs :batchs="batchs" />
      </div>
    </div>
    <div class="table-box">
      <el-table
        ref="table"
        :data="tableData"
        class="table"
        :height="
          table.props && table.props.tableHeight
            ? table.props.tableHeight
            : tableHeight
        "
        highlight-current-row
      >
        <BaseTableColumn
          v-for="(column, index) in columns"
          :key="index"
          :column="column"
        />
        <el-table-column
          v-if="actionColumns"
          :label="actionColumns.label || '操作'"
          fixed="right"
          :width="actionColumns.width || 110"
        >
          <template #default="{ row }">
            <BaseColumnActions
              :row="row"
              :actions="actionColumns.actions"
              :outActionsCont="actionColumns.showBtnCont"
              @action-click="handleActionClick"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      v-if="showPage"
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
      :key="pageKey"
    >
    </el-pagination>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Batchs, TableCrud } from "@/types";
import { ElTable } from "element-ui/types/table";

@Component({
  name: "BaseCrud",
  components: {
    BaseColumnActions: () =>
      import("@/components/Base/BaseCrud/BaseColumnActions.vue"),
    BaseColumnFilter: () =>
      import("@/components/Base/BaseCrud/BaseColumnFilter.vue"),
    BaseCrudBatchs: () =>
      import("@/components/Base/BaseCrud/BaseCrudBatchs.vue"),
    BaseTableColumn: () =>
      import("@/components/Base/BaseCrud/BaseTableColumn.vue"),
  },
})
export default class BaseCrud extends Vue {
  @Prop({ type: Object, default: () => {}, required: true })
  table?: TableCrud<any>;

  loading: boolean = false;
  showPage: boolean = true;
  tableHeight: any = 100;
  tableData: any = [];

  pageKey: any = String(new Date().getTime());

  pagination: any = {
    currentPage: 1,
    pageSizes: [10, 30, 50],
    pageSize: 10,
    total: 100,
    layout: "prev, pager, next, jumper, sizes, total",
  };

  get tableConfig() {
    return this.table;
  }

  get filters() {
    return (this.table as any).filters || [];
  }

  get service() {
    return (this.table as any).service || {};
  }

  get columns() {
    return (this.table as any).columns || [];
  }

  get actionColumns() {
    return (this.table as any).actionColumns;
  }

  get batchs() {
    let defaultBatchs: Array<Batchs<any>> = [
      { label: "刷新", icon: "el-icon-refresh", handler: this.reloadTable },
    ];

    return [...defaultBatchs, (this.table as any).batchs] || [];
  }

  @Watch("service", { immediate: true, deep: true })
  async wathchService() {
    await this.handleBuildTableData();
  }

  mounted() {
    window.onresize = () => {
      this.handleBuildTableHeight();
    };
  }

  async handleBuildTableData() {
    let service: any = this.service;
    let name = "$" + service.name;
    let method = service.method;
    let data = service.data;
    let params = {
      pageNo: 1,
      pageSize: 10,
      ...service.params,
    };
    this.loading = true;
    let resp = await this[name][method](params, data);
    this.loading = false;
    if (resp.err == 0 && resp.data) {
      this.showPage = true;
      let arr = resp.data.data;
      this.tableData = (this.table as any).renderMethod
        ? await (this.table as any).renderMethod(arr, data)
        : arr;
      this.pagination.total = resp.data.total;
      this.pagination.pageSize = resp.data.pageSize;
    } else {
      this.showPage = false;
      this.tableData = [];
    }
    this.handleBuildTableHeight();
  }

  async reloadTable() {
    await this.handleBuildTableData();
  }

  handleBuildTableHeight() {
    let table: any = document.getElementsByClassName("table-box")[0];
    let tableHeight = table ? table.offsetHeight : 200;
    this.tableHeight = tableHeight;
  }

  async handleSearch(queryData: any) {
    (this.service as any).params = {
      pageNo: 1,
      pageSize: 10,
    };
    (this.service as any).data = { ...queryData };
    console.log("(this.service as any).", (this.service as any).data);
    this.pagination.currentPage = 1;
    this.pageKey = String(new Date().getTime());
    await this.reloadTable();
  }

  async handleSizeChange(val: any) {
    this.$set((this.service as any).params, "pageNo", 1);
    this.$set((this.service as any).params, "pageSize", val);
    this.pagination.currentPage = 1;
    await this.reloadTable();
  }
  async handleCurrentChange(val: any) {
    this.$set((this.service as any).params, "pageNo", val);
    await this.reloadTable();
  }
  handleActionClick(row: any, action: any, $event: any) {
    (this.$refs.table as ElTable).setCurrentRow(row);
  }
}
</script>
<style lang="scss" scoped>
.base-crud {
  border: 1px solid #ebeef5;
  height: 100%;
  position: relative;
  .table-header {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border-bottom: 1px solid #ebeef5;
    .table-header-left {
      align-items: center;
      display: flex;
      gap: 20px;
    }
    .table-header-right {
      align-items: center;
      display: flex;
    }
  }
  .table-box {
    height: calc(100% - 100px);
  }

  .pagination {
    position: absolute;
    bottom: 8px;
    right: 0px;
  }
}
</style>
