import { CommonObj } from "@/types";
import Plugin from "@/plugins/plugin";
import { http } from "@/modules/http";

export class MaterielService extends Plugin {
  name = "materielService";

  constructor() {
    super();
  }

  serviceUrl() {
    return "/materiel";
  }
  serviceUrlA() {
    return "/materiel-log";
  }
  getMaterielListPage(params: CommonObj, data: CommonObj) {
    return http(`${this.serviceUrl()}/query_materiel_page_list`, {
      method: "post",
      params,
      data,
    });
  }
  // 添加物料
  handleAddMateriel(data: CommonObj) {
    return http(`${this.serviceUrl()}/add_materiel`, { method: "post", data });
  }
  // 修改物料
  handleEditMateriel(data: CommonObj) {
    return http(`${this.serviceUrl()}/edit_materiel`, { method: "post", data });
  }

  handleDeleteMateriel(data: CommonObj) {
    return http(`${this.serviceUrl()}/delete_materiel`, {
      method: "post",
      data,
    });
  }

  handleSelectMaterielById(data: CommonObj) {
    return http(`${this.serviceUrl()}/select_materiel_by_id`, {
      method: "post",
      data,
    });
  }

  getMaterielLogListPage(params: CommonObj, data: CommonObj) {
    return http(`${this.serviceUrlA()}/query_materiel_log_page_list`, {
      method: "post",
      params,
      data,
    });
  }
}
