<template>
  <el-dialog
    :visible.sync="visible"
    :width="width"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
    custom-class="base-dialog"
  >
    <div slot="title" class="dialog__header">
      <span>{{ title }}</span>
      <i class="el-icon-close" @click="handleCancel" />
    </div>
    <slot name="body" v-loading="loading"></slot>
    <div slot="footer" v-if="showFooter">
      <el-button @click="handleCancel" size="small" :disabled="loading">
        {{ cancelText }}
      </el-button>
      <el-button
        type="primary"
        @click="handleSave"
        size="small"
        :disabled="loading"
      >
        {{ canterText }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "BaseDialog",
})
export default class BaseDialog extends Vue {
  @Prop({ type: Boolean, default: false }) visible!: boolean;
  @Prop({ type: Boolean, default: true }) showFooter!: boolean;
  @Prop({ type: String, default: "" }) title!: string;
  @Prop({ type: String, default: "400px" }) width!: string;

  @Prop({ type: Boolean, default: false }) loading!: boolean;

  @Prop({ type: String, default: "取 消" }) cancelText!: string;
  @Prop({ type: String, default: "确 定" }) canterText!: string;

  handleSave() {
    this.$emit("handleSave");
  }
  handleCancel() {
    this.$emit("handleCancel");
  }
}
</script>
<style lang="scss" scoped>
.base-dialog {
  .dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    span {
      font-size: 18px;
      font-weight: 600;
    }
    i {
      cursor: pointer;
      &:hover {
        color: #409eff;
      }
    }
  }
}
</style>
