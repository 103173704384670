import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import helper from "@/plugins/helper";
import interaction from "@/plugins/interaction";

import VTooltip from "v-tooltip";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "@/assets/styles/index.scss";

import api from "@/plugins/api";

// 通用组件
import BaseCrud from "@/components/Base/BaseCrud.vue";
import BaseDialog from "@/components/Base/BaseDialog.vue";

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(api);
Vue.use(helper);
Vue.use(interaction);
Vue.use(VTooltip, {
  defaultBoundariesElement: "body",
});

// 通用组件
Vue.component("BaseCrud", BaseCrud);
Vue.component("BaseDialog", BaseDialog);

// input 文本输入框 防止输入html标签
document.addEventListener("input", (event: any) => {
  if (event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA") {
    const inputValue = event.target.value || "";
    if (/<[^>]*>/g.test(inputValue)) {
      Vue.prototype.$interaction.showError("请勿输入HTML标签");
      event.target.value = inputValue.replace(/<[^>]*>/g, "");
      event.preventDefault();
    }
  }
});

// 全局窗口大小监听器
function checkWindowSize() {
  if (window.innerWidth < 1000) {
    Vue.prototype.$interaction.showWarning(
      "请使用大于1000px的屏幕宽度访问本网站，以获得更好的体验，感谢你的理解！"
    );
  }
}

// 添加事件监听器
window.addEventListener("resize", checkWindowSize);

// 初始化检查窗口大小
checkWindowSize();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
