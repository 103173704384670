import { CommonObj } from "@/types";
import Plugin from "@/plugins/plugin";
import { http } from "@/modules/http";

export class UserService extends Plugin {
  name = "userService";

  constructor() {
    super();
  }

  serviceUrl() {
    return "/user";
  }

  queryUserList(params: CommonObj) {
    return http(`${this.serviceUrl()}/query_user_list`, {
      method: "get",
      params,
    });
  }
  queryUserPageList(params: CommonObj, data: CommonObj) {
    return http(`${this.serviceUrl()}/query_user_page_list`, {
      method: "post",
      params,
      data,
    });
  }
  handleAddUser(data: CommonObj) {
    return http(`${this.serviceUrl()}/add_user`, { method: "post", data });
  }
  handleEditUser(data: CommonObj) {
    return http(`${this.serviceUrl()}/edit_user`, { method: "post", data });
  }
  handleDeleteUser(data: CommonObj) {
    return http(`${this.serviceUrl()}/delete_user`, {
      method: "post",
      data,
    });
  }
  selectUserIsLogin(data: CommonObj) {
    return http(`${this.serviceUrl()}/select_user_is_logoin`, {
      method: "post",
      data,
    });
  }
}
