import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/LoginRoute.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home/HomeRoute.vue"),
    meta: { requiresAuth: true }, // 需要登录才能访问
    children: [],
  },
];

// export const asyncRouterMap: RouteConfig[] = [
//   {
//     path: "/",
//     component: () => import("@/views/HomeRoute.vue"),
//     children: [],
//   },
//   {
//     path: "*",
//     redirect: "/404",
//   },
// ];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

function isAuthenticated() {
  // 例如：判断 localStorage 中是否存储了用户信息
  return localStorage.getItem("userInfo") !== null;
}

// 全局前置守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 需要认证的路由
    if (!isAuthenticated()) {
      // 如果用户未登录，重定向到登录页面
      next({ name: "login" });
    } else {
      // 用户已登录，放行
      next();
    }
  } else {
    // 不需要认证的路由，直接放行
    next();
  }
});

export default router;
