import { CommonObj } from "@/types";
import Plugin from "@/plugins/plugin";
import { http } from "@/modules/http";

export class ClassFiService extends Plugin {
  name = "classFiService";

  constructor() {
    super();
  }

  serviceUrl() {
    return "/classFi";
  }

  getClassFiList(params: CommonObj, data: CommonObj) {
    return http(`${this.serviceUrl()}/query_class_fi_list`, { params, data });
  }
  handleSaveClassFi(data: CommonObj) {
    return http(`${this.serviceUrl()}/add_class_fi`, { method: "post", data });
  }
  handleEditClassFi(data: CommonObj) {
    return http(`${this.serviceUrl()}/edit_class_fi`, { method: "post", data });
  }
  handleDeleteClassFi(data: CommonObj) {
    return http(`${this.serviceUrl()}/delete_class_fi`, {
      method: "post",
      data,
    });
  }
}
