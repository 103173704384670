import {
  ElNotificationOptions,
  LoadingServiceOptions,
  CommonObj,
  ConfirmOptions,
} from "@/types";
import Plugin from "../plugin";
import Vue, { VueConstructor } from "vue";
class Interaction extends Plugin {
  vm: Vue;
  name = "interaction";
  constructor(Vue: VueConstructor) {
    super(Vue);
    this.vm = Vue.prototype;
  }

  showMessage(message: string, extraOptions?: Partial<ElNotificationOptions>) {
    this._notify(
      { type: "info", title: "提示", message, duration: 5000, offset: 100 },
      extraOptions
    );
  }

  showSuccess(message: string, extraOptions?: Partial<ElNotificationOptions>) {
    this._notify(
      {
        type: "success",
        title: "成功提示",
        message,
        duration: 1500,
        offset: 100,
      },
      extraOptions
    );
  }

  showWarning(message: string, extraOptions?: Partial<ElNotificationOptions>) {
    this._notify(
      { type: "warning", title: "警告", message, duration: 2000, offset: 100 },
      extraOptions
    );
  }

  showError(message: string, extraOptions?: Partial<ElNotificationOptions>) {
    this._notify(
      {
        type: "error",
        title: "操作错误",
        message,
        duration: 2500,
        offset: 100,
      },
      extraOptions
    );
  }

  _notify(
    options: ElNotificationOptions,
    extraOptions?: Partial<ElNotificationOptions>
  ) {
    const api = this.vm.$message;
    // 每次打开提示 关闭之前的提示
    (api as any).closeAll();

    extraOptions = {
      ...extraOptions,
      title: options.title,
      message: options.message,
    };

    options.position = extraOptions.position || "bottom-right";
    options.customClass = `customized-notify-message ${
      extraOptions.customClass || ""
    }`;
    options.duration =
      typeof extraOptions.duration === "number"
        ? extraOptions.duration
        : options.duration;
    options.showClose =
      typeof extraOptions.showClose === "boolean"
        ? extraOptions.showClose
        : false;
    options.dangerouslyUseHTMLString =
      typeof extraOptions.dangerouslyUseHTMLString === "boolean"
        ? extraOptions.dangerouslyUseHTMLString
        : false;

    api(options);
  }

  showLoading(options: LoadingServiceOptions) {
    return this.vm.$loading(options);
  }

  showConfirm(options: ConfirmOptions) {
    if (!options || !this.vm.$misc.isJson(options)) {
      return;
    }

    const doNothing = () => {};
    this.vm
      .$confirm(options.message as string, options.title || "操作确认", {
        ...options,
        //  customClass: options.customClass || "simple",
        dangerouslyUseHTMLString: true,
        confirmButtonText: options.confirmButtonText
          ? options.confirmButtonText
          : "确 定",
        cancelButtonText: options.cancelButtonText
          ? options.cancelButtonText
          : "取 消",
        type: options.type || "warning",
        showClose: options.showClose === undefined ? true : !!options.showClose,
        closeOnPressEscape:
          options.closeOnPressEscape === undefined
            ? true
            : !!options.closeOnPressEscape,
        closeOnClickModal:
          options.closeOnClickModal === undefined
            ? false
            : !!options.closeOnClickModal,
      })
      .then(() => options.confirmed())
      .catch((action) =>
        options.canceled ? options.canceled(action) : doNothing()
      );
  }
}

export default Interaction;
